<template>
	<Teleport to="body">
		<transition name="fade">
			<div v-if="status" class="qr-reader-container">
				<qrcode-stream
					@detect="onDetect"
					@camera-on="onCameraOn"
					@camera-error="onCameraError"
				></qrcode-stream>
				<MaoButton
					class="close-button"
					variant="opacity-light"
					icon="close"
					@click="turnOffCamera"
				></MaoButton>
			</div>
		</transition>
		<transition name="fade">
			<div class="confirmation" v-if="decodedValue">
				<div class="artwork-found">{{decodedValue.title}}</div>
				<MaoButton @click="goToArtwork" :label="labels['Go to Artwork']"></MaoButton>
			</div>
		</transition>
	</Teleport>
</template>
<script>
import { defineComponent, ref, onUnmounted } from "vue";
import { QrcodeStream } from "vue-qrcode-reader";
import { useRouter } from "vue-router";
import { useAppDataStore } from "./../stores/appData";
import MaoButton from "./MaoButton.vue";

export default defineComponent({
	name: "QrScanner",
	components: {
		QrcodeStream,
		MaoButton,
	},
	props: {
		status: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		labels() {
			return this.appDataStore.appData.labels;
		},
	},
	setup(props, { emit }) {
		const appDataStore = useAppDataStore();
		const decodedValue = ref(null);
		const router = useRouter();
		let trackingTimeout = null;

		const goToArtwork = () => {
			if (decodedValue.value) {
				router.push({ name: 'Artwork', params: { id: decodedValue.value.id }});
				setTimeout(() => {
					decodedValue.value = null;
					emit("update:status", false);
				}, 500);
			}
		};

		const turnOffCamera = () => {
			emit("update:status", false);
		};

		const onCameraOn = () => {
			console.log("Fotocamera inizializzata con successo");
		};

		const onCameraError = (error) => {
			console.error("Errore nell'inizializzazione della fotocamera:", error);
			alert("Impossibile accedere alla fotocamera. Per favore, controlla i permessi.");
			turnOffCamera();
		};

		const onStop = () => {
			console.log("Fotocamera spenta");
		};

		const resetScanner = () => {
			decodedValue.value = null;
			emit("reset-scanner");
		};

		const getArtworkById = (artworkId) => {
			const artworks = appDataStore.appData['artworks'];
			for(let artwork of artworks) {
				if(artwork.id == artworkId) {
					return artwork;
				}
			}
			return false;
		};

		const onDetect = (detectedCodes) => {
			console.log("QR Code rilevati:", detectedCodes);
			
			if (trackingTimeout) {
				clearTimeout(trackingTimeout);
			}

			trackingTimeout = setTimeout(() => {
				decodedValue.value = null;
			}, 1000);

			if (!detectedCodes || detectedCodes.length === 0) {
				decodedValue.value = null;
				return;
			}

			// Estrai l'URL dal QR code
			const rawUrl = detectedCodes[0].rawValue;
			console.log("URL scansionato:", rawUrl);
			
			// Estrai l'ID dell'opera dall'URL
			const code = rawUrl.match(/artwork\/(\d+)/);
			if (code) {
				console.log("ID opera trovato:", code[1]);
				const artwork = getArtworkById(code[1]);
				if (artwork) {
					console.log("Opera trovata:", artwork.title);
					decodedValue.value = artwork;
				} else {
					console.log("Opera non trovata con ID:", code[1]);
				}
			} else {
				console.log("Formato QR code non riconosciuto");
			}
		};

		onUnmounted(() => {
			if (trackingTimeout) {
				clearTimeout(trackingTimeout);
			}
		});

		return {
			decodedValue,
			turnOffCamera,
			appDataStore,
			onCameraOn,
			onCameraError,
			onStop,
			resetScanner,
			goToArtwork,
			onDetect
		};
	},
});
</script>

<style scoped lang="scss">
.qr-reader-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: black;
}

.close-button {
	position: fixed;
	top: 1rem;
	right: 0.9rem;
	@include breakpoint(xs) {
		top: 0.7rem;
		right: 0.7rem;
	}
}

.start-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.confirmation {
	position: fixed;
	bottom: 50px;
	width: 100%;
	text-align: center;
	z-index: 1000;

	p {
		color: #333;
		font-size: 1.2em;
	}

	Button {
		margin: 10px;
	}
}
.artwork-found {
    position: fixed;
	width: 100%;
	bottom: 1rem;
	text-align: center;
	color: white;
	text-shadow: 2px 2px 4px #000000;
}
</style>

<template>
	<div class="navigation-menu" aria-label="Main navigation menu">
		<button class="navigation-button-menu" :class="{toggled: isHome()}" @click="goToHome" aria-label="Home" :aria-pressed="isHome()">
			<div class="icon-home-outline" aria-hidden="true"></div>
		</button>
		<button class="navigation-button-menu" :class="{toggled: isPaths()}" @click="goToPaths" aria-label="Paths" :aria-pressed="isPaths()">
			<div class="icon-route-outline" aria-hidden="true"></div>
		</button>
		<button class="navigation-button-menu" :class="{toggled: isArtworks()}" @click="goToArtworks" aria-label="Artworks" :aria-pressed="isArtworks()">
			<div class="icon-search" aria-hidden="true"></div>
		</button>
		<button class="navigation-button-menu" :class="{toggled: isExplore()}" @click="goToExplore" aria-label="Explore" :aria-pressed="isExplore()">
			<div class="icon-explore-outline" aria-hidden="true"></div>
		</button>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				currentRoute: ''
			}
		},
		watch: {
			$route(to) {
				this.currentRoute = to.name
			}
		},
		methods: {
			isHome() {
				const cases = ['Home', ''];
				return cases.includes(this.$route.name);
			},
			isPaths() {
				const cases = ['Paths', 'Path'];
				return cases.includes(this.$route.name);
			},
			isArtworks() {
				const cases = ['Artworks', 'Artwork'];
				return cases.includes(this.$route.name);
			},
			isExplore() {
				const cases = ['Explore'];
				return cases.includes(this.$route.name);
			},
			goToHome() {
				this.$router.push({ name: 'Home', lang: this.$route.params.lang });
			},
			goToPaths() {
				this.$router.push({ name: 'Paths', lang: this.$route.params.lang });
			},
			goToArtworks() {
				this.$router.push({ name: 'Artworks', lang: this.$route.params.lang });
			},
			goToExplore() {
				this.$router.push({ name: 'Explore', lang: this.$route.params.lang });
			}
		}		
	}
</script>

<style scoped lang="scss">
.navigation-menu {
	display: flex;
	padding: var(--padding-s);
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-radius: var(--border-radius-s);
	background: var(--surface-contrast-container-low);

	.navigation-button-menu {
		display: flex;
		padding: var(--padding-s);
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: var(--gap-s);
		border-radius: var(--border-radius-xs);
		background: transparent;
		color: var(--text-on-surface-variant-high);
		transition: all 0.2s;
		cursor: pointer;

		@include generateButtonStates(
			(
				hover: (
					background: var(--surface-opacity-low-dark),
					color: var(--text-on-surface-invert)
				),
				pressed: (
					background: var(--surface-opacity-low-dark),
					border-radius: var(--border-radius-s),
					color: var(--text-on-surface-invert)
				),
				toggled: (
					border-radius: var(--border-radius-s),
					background: var(--surface-opacity-standard-dark)
				)
			)
		);

		[class^="icon-"], [class*="icon-"] {
			font-size: var(--icon-size-m) !important;
		}

		@mixin filledIcons() {
			.icon-home-outline::before {
				content: "\e91b";
			}
			.icon-route-outline::before {
				content: "\e92b";
			}
			.icon-sculpture-outline::before {
				content: "\e92e";
			}
			.icon-explore-outline::before {
				content: "\e90f";
			}
		}

		// Hover solo in desktop
		@media (min-width: 1024px) {
			&:hover:not(.toggled, .disabled, :active) {
				@include filledIcons();
			}
		}
	
		&:active:not(.toggled, .disabled) {
			@include filledIcons();
		}
	
		&.toggled {
			@include filledIcons();
		}

	}
}
</style>

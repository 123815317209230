import axios from "axios";

const instance = axios.create({
	//baseURL: 'https://qzrstudio.com/mao-opere/wp-json',
	//baseURL: 'https://localhost/mao-opere-in-rete/wp-json',
	baseURL: 'https://maoapp.maotorino.it/wp-json',
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json"
	}
	// Puoi aggiungere qui altre configurazioni necessarie
});

// Aggiungi un interceptor di richiesta
instance.interceptors.request.use(
	config => {
		// Verifica se l'URL ha già una query string
		if (config && config.url) {
			const separator = config.url.includes("?") ? "&" : "?";
			// Aggiungi `?vue=true` o `&vue=true` all'URL, a seconda del caso
			config.url += `${separator}vue=true`;
			return config;
		}
		return config;
	},
	error => {
		// Gestisci eventuali errori della richiesta
		return Promise.reject(error);
	}
);

export default instance;
import axios from '@/axios.config';
import { defineStore } from "pinia";

export const useAppDataStore = defineStore("appData", {
	state: () => ({
		appData: {
			artworks: [],
			paths: [],
			loaded: false,
			lang: 'it',
			langLabel: 'Italiano',
			labels: {},
			languages: {}
		}
	}),

	actions: {
		async getAppData(lang = 'it') {
			const originalAppData = this.appData;
			const appData = await axios.get("/qzr-data/app-data?lang=" + lang);
			this.appData = Object.assign({}, originalAppData, appData.data);

		},
		setAppDataLoaded(status) {
			this.appData.loaded = status;
		},
		loadLang(langCode, router) {
			this.appData.lang = langCode;
			
			if(!this.appData.languages) {
				this.getLanguages();
			}

			const lang = this.appData.languages[langCode];
			this.appData.langLabel = lang.native_name;

			router.push({ name: 'Home', params: { lang: langCode } });
		},
		async setLabels(lang = 'it') {
			const labels = await axios.get("/qzr-data/wpml-strings?lang=" + lang);
			this.appData.labels = labels.data;
			this.appData.langLabel = this.appData.languages && this.appData.languages[lang]? this.appData.languages[lang].native_name : 'Italiano';
		},
		async getLanguages() {
			const languages = await axios.get("/qzr-data/wpml-languages");
			this.appData.languages = languages.data;
		}
	}
});
<template>
	<Teleport to="body">
	<div v-if="status">
		<transition name="fade">
		<div>
			<MaoOverlay :status="status" @close="stopAndClose" />
			<div v-if="status" class="fake-overlay" @click="stopAndClose"></div>
			<div class="grid-x video-container" :class="{ active: status }">
			<MaoButton
				class="close-button-video-player"
				variant="text-button-opacity-light"
				icon="close"
				@close="stopAndClose"
			/>

			<!-- Container per il player Vimeo -->
			<div ref="playerContainer" class="vimeo-player-container"></div>
		</div>
		<div class="grid-x title-container" :class="{ active: status }">
			<div class="title body-small">{{ title }}</div>
			</div>
		</div>
		</transition>
	</div>
	</Teleport>
</template>

<script>
import MaoOverlay from "./MaoOverlay.vue";
import MaoButton from "./MaoButton.vue";
import { useGlobalActionsStore } from "@/stores/globalActions";
import { useBottomMenuStore } from "@/stores/bottomMenu";
import Player from "@vimeo/player";

export default {
	name: "VimeoPlayer",
	emits: ["close"],
	components: {
	MaoOverlay,
	MaoButton
	},
	props: {
	title: {
		type: String,
		required: true
	},
	video: {
		type: Object,
		required: true
	},
	status: {
		type: Boolean,
		required: true
	}
	},
	data() {
	return {
		vimeoPlayer: null
	};
	},
	setup() {
	const globalActionsStore = useGlobalActionsStore();
	const bottomMenuStore = useBottomMenuStore();
	return { globalActionsStore, bottomMenuStore };
	},
	computed: {
	videoUrl() {
		// Regex modificata per gestire sia "vimeo.com/..." che "player.vimeo.com/video/..."
		const regex = /(?:vimeo\.com\/(?:video\/)?)(\d+)(?:\/(\w+))?/;
		const match = this.video.url.match(regex);

		if (match) {
		const videoId = match[1];
		const privateToken = match[2] ? `?h=${match[2]}` : "";
		// L'URL usato qui non serve più per l'iframe, ma lo utilizziamo per configurare il player.
		return `https://player.vimeo.com/video/${videoId}${privateToken}`;
		} else {
		console.error("URL Vimeo non valido:", this.video.url);
		return "";
		}
	}
	},
	watch: {
	status(newVal) {
		if (newVal) {
		this.globalActionsStore.hideHeader();
		this.bottomMenuStore.hideBottomMenu();
		this.initializePlayer();
		} else {
		this.globalActionsStore.showHeader();
		this.bottomMenuStore.showBottomMenu();
		this.destroyPlayer();
		}
	}
	},
	methods: {
		initializePlayer() {
    if (this.vimeoPlayer || !this.videoUrl) return;

    this.$nextTick(() => {
      const container = this.$refs.playerContainer;
      if (!container) {
        console.error("Container per il player non trovato!");
        return;
      }
      this.vimeoPlayer = new Player(container, {
        url: this.videoUrl,
        autoplay: true,   // se vuoi far partire automaticamente il video
        autopause: true   // altre opzioni come preferisci
      });
      
      this.vimeoPlayer.on("loaded", () => {
        console.log("Video caricato");
      });
      this.vimeoPlayer.on("error", error => {
        console.error("Errore nel player:", error);
      });
    });
  },
	destroyPlayer() {
		if (this.vimeoPlayer) {
		this.vimeoPlayer.unload().then(() => {
			return this.vimeoPlayer.destroy();
		}).then(() => {
			this.vimeoPlayer = null;
		}).catch(err => {
			console.error("Errore durante lo spegnimento del player:", err);
		});
		}
	},
	stopAndClose() {
		if (this.vimeoPlayer) {
		this.vimeoPlayer.pause().then(() => {
			console.log("Video messo in pausa");
		}).catch(err => {
			console.error("Errore durante la pausa del video", err);
		});
		}
		this.$emit("close");
	}
	},
	beforeUnmount() {
	this.destroyPlayer();
	}
};
</script>

<style scoped lang="scss">
.fake-overlay {
	position: fixed;
	inset: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5;
}
.video-container {

	position: fixed;
    width: 100%;
    display: inline-grid;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    left: 50%;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
	
	.vimeo-player-container {
		grid-column: main-column;
		position: relative;
		inset: 0;
		width: 100%;
		aspect-ratio: 16 / 9;
	}
	
	.close-button-video-player {
	position: absolute;
	right: 1rem;
	z-index: 100;
	top: -1.5rem;
	}
}

.title-container {
	position: fixed;
	bottom: 1rem;
	display: grid;
	z-index: 2;
	opacity: 0;
	transition: all 0.3s;
	pointer-events: none;
	width: 100%;
	
	.title {
	color: white;
	grid-column: main-column;
	}
}

.active {
	opacity: 1;
	pointer-events: all;
}
</style>
<style lang="scss">
.vimeo-player-container {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			aspect-ratio: 16 / 9;
			max-width: 80%;
		}
	}

</style>
